<template>
  <div id="screenPage">
    <div class="hero">
      <h4 class="title is-4">
        <BackButton/> BMI <small class="headerSmall">Calculator</small>
        <!-- <small class="headerSmall">Health & Wellbeing</small> -->
      </h4>
    </div>
    <section>
      <div>
        <div style="max-width: 400px; margin: auto">
          <van-field
            readonly
            clickable
            name="picker"
            :value="units"
            size="large"
            label="Units:"
            placeholder="Select Metric unit"
            label-width="7em"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              confirm-button-text="Confirm"
              cancel-button-text="Cancel"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <!-- <van-field
          v-model="age"
          type="number"
          label="Age"
          placeholder="Age"
          label-width="7em"
        /> -->
          <van-field
            v-model="weight"
            type="number"
            size="large"
            :label="units === 'Metric' ? 'Weight (kg)' : 'Weight (lbs)'"
            placeholder="Weight"
            label-width="7em"
            maxlength="4"
          />
          <van-field
            v-if="units === 'Metric'"
            v-model="heightMetric"
            type="number"
            label="Height (cm)"
            size="large"
            placeholder="Height"
            label-width="7em"
            maxlength="3"
          />
          <van-field
            v-if="units === 'Imperial'"
            type="number"
            label="Height (ft/inch)"
            placeholder="Height"
            size="large"
            label-width="7em"
          ><template #input>
            <input
              v-model="heightFeet"
              class="van-field__control"
              type="number"
              placeholder="feet"
              maxlength="2"
            />
            <input
              v-model="heightInch"
              class="van-field__control"
              type="number"
              placeholder="inch"
              maxlength="2"
            />
          </template>
          </van-field>
          <span
            v-if="summaryText.length === 0"
            class="title is-7 has-text-grey-light"
          >Please fill above to reveal result</span>
        </div>
        <transition name="slide-fade" mode="out-in">
          <van-empty v-if="summaryText.length === 0" image="search" />
          <div id="resultDiv" :key="summaryText">
            <b style="padding: 10px">{{ summaryText }}</b><br />
            <b-button
              v-if="summaryText.length > 0"
              id="saveToJournalButton"
              type="is-primary is-light"
              @click="saveToJournal"
            ><i class="fas fa-book"></i> Save to Journal</b-button>
            <hr v-if="summaryText.length > 0" />
            <p v-html="explanationText"></p>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
// import Roller from 'vue-roller'
import ApiService from '@/services/ApiService'
import BackButton from '@/components/BackButton';
export default {
  name: 'BmiCalculator',
  components: {
    BackButton
    // Roller,
  },
  data() {
    return {
      showPicker: false,
      columns: ['Metric', 'Imperial'],
      units: 'Metric',
      weight: null,
      heightMetric: null,
      heightFeet: null,
      heightInch: null,
      age: null,
      summaryText: '',
      explanationText: '',
      bmi: null
    }
  },
  watch: {
    weight() {
      if (
        this.weight &&
        (this.heightMetric || (this.heightInch && this.heightFeet))
      ) {
        this.calculate()
      }
    },
    heightMetric() {
      if (
        this.weight &&
        (this.heightMetric || (this.heightInch && this.heightFeet))
      ) {
        this.calculate()
      }
    },
    heightInch() {
      if (this.weight && this.height) {
        this.calculate()
      }
    },
  },
  created() {},
  methods: {
    onConfirm(value) {
      this.units = value
      this.showPicker = false
    },
    calculate() {
      let height = 0
      let bmi = 0
      let message = ''
      if (
        this.weight < 30 ||
        (this.heightMetric < 10 && !this.heightFeet && !this.heightInch)
      ) {
        this.explanationText = ''
        this.summaryText = ''
        return
      }
      if (this.units != 'Metric') {
        height = parseInt(this.heightFeet) * 12 + parseInt(this.heightInch)
        // alert(height);
        bmi = (this.weight * 703) / (height * height)
      } else {
        height = this.heightMetric / 100
        bmi = this.weight / (height * height)
      }

      bmi = bmi.toFixed(1)

      if (bmi <= 18.5) {
        message = 'Underweight'
      } else if (bmi > 18.5 && bmi <= 24.9) {
        message = 'Normal'
      } else if (bmi >= 25 && bmi <= 29.9) {
        message = 'Overweight'
      } else if (bmi >= 30) {
        message = 'Obese'
      }
      this.bmi = bmi;
      this.summaryText = `Your Body Mass Index (BMI) is ${bmi}. This means your weight is within the ${message} range.`
      this.generateFullMessage(message, height)
    },
    generateFullMessage(bmiMessage, height) {
      if (bmiMessage != 'Normal') {
        if (this.units === 'Metric') {
          const minWeightkg = (18.5 * (height * height)).toFixed(1)

          const maxWeightKg = (24.9 * (height * height)).toFixed(1)
          if (bmiMessage === 'Underweight') {
            this.explanationText = `Your current BMI is lower than the recommended range of <strong>18.5</strong> to <strong>24.9</strong>. <br>To be within the right range for your height, you should weigh between <strong>${minWeightkg} kg</strong> and <strong>${maxWeightKg} kg</strong>`
          } else if (bmiMessage === 'Overweight' || bmiMessage === 'Obese') {
            this.explanationText = `Your current BMI is greater than the recommended range of <strong>18.5</strong> to <strong>24.9</strong>. <br>To be within the right range for your height, you should weigh between <strong>${minWeightkg} kg</strong> and <strong>${maxWeightKg} kg</strong>`
          }
        } else if (this.units === 'Imperial') {
          const minWeight = (18.5 * (height * height)) / 703
          const maxWeight = (24.9 * (height * height)) / 703
          const minWeightLbs = (minWeight * 0.453).toFixed(1)
          const maxWeightLbs = (maxWeight * 0.453).toFixed(1)
          if (bmiMessage === 'Underweight') {
            this.explanationText = `Your current BMI is lower than the recommended range of <strong>18.5</strong> to <strong>24.9</strong>. <br>To be within the right range for your height, you should weigh between <strong>${minWeightLbs} lbs</strong> and <strong>${maxWeightLbs} lbs</strong>`
          } else if (bmiMessage === 'Overweight' || bmiMessage === 'Obese') {
            this.explanationText = `Your current BMI is greater than the recommended range of <strong>18.5</strong> to <strong>24.9</strong>. <br>To be within the right range for your height, you should weigh between <strong>${minWeightLbs} lbs</strong> and <strong>${maxWeightLbs} lbs</strong>`
          }
        }
      } else {
        this.explanationText = 'You seem to keep your weight normal. Well done!'
      }
    },
    saveToJournal() {
      this.$notify({ type: 'success', message: 'Saved', duration: 1000 })
      ApiService.journal.saveQuickEntry({
        template_id: 2,
        value: this.bmi,
        type: 'number'
      });
    },
  },
}
</script>
<style scoped>
#saveToJournalButton {
  height: auto;
  margin: auto;
  margin-top: 20px;
}
#resultDiv {
  /* margin-left: 50px; */
  margin-top: 40px;
  text-align: center;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.roller .rollerBlock {
  /* font-size: 25px; */
}
</style>
