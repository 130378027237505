<template>
  <div class="feed container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">
        <BackButton/> Calorie Burned <small class="headerSmall">Calculator</small>
      </h4>
    </div>
    <section>
      <div>
        <div style="max-width: 400px; margin: auto">
          <van-field
            readonly
            clickable
            name="picker"
            :value="units"
            label="Units:"
            size="large"
            placeholder="Select Metric unit"
            label-width="7em"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              confirm-button-text="Confirm"
              cancel-button-text="Cancel"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            v-model="mins"
            type="number"
            label="Time (mins)"
            placeholder="Time (mins)"
            size="large"
            label-width="7em"
            maxlength="4"
          />
          <van-field
            v-model="weight"
            type="number"
            :label="units === 'Metric' ? 'Weight (kg)' : 'Weight (lbs)'"
            placeholder="Weight"
            size="large"
            label-width="7em"
            maxlength="4"
          />
          <van-field
            readonly
            clickable
            name="picker3"
            :value="activity"
            size="large"
            label="Activity:"
            placeholder="Select Activity"
            label-width="7em"
            @click="showActivityPicker = true"
          />
          <van-popup v-model="showActivityPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="activityChoices"
              visible-item-count="15"
              item-height="33"
              confirm-button-text="Confirm"
              cancel-button-text="Cancel"
              @confirm="onConfirmActivity"
              @cancel="showActivityPicker = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker4"
            :value="selectedFood"
            size="large"
            label="Display as Food:"
            placeholder="Select Food"
            label-width="7em"
            @click="showFoodPicker = true"
          />
          <van-popup v-model="showFoodPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="foodChoices"
              confirm-button-text="Confirm"
              cancel-button-text="Cancel"
              @confirm="onConfirmFood"
              @cancel="showFoodPicker = false"
            />
          </van-popup>
          <span
            v-if="!totalCaloriesBurned"
            class="title is-7 has-text-grey-light"
          >Please fill above to reveal result</span>
        </div>
        <transition-group name="slide-fade" mode="out-in">
          <van-empty v-if="!totalCaloriesBurned" :key="'1'" image="search" />
          <div v-if="totalCaloriesBurned" id="resultDiv" key="2">
            You would burn ~ total of <b>{{ totalCaloriesBurned }} kcal</b><br />
            <b-button
              v-if="totalCaloriesBurned"
              id="saveToJournalButton"
              type="is-primary is-light"
              size="is-small"
              @click="saveToJournal"
            ><i class="fas fa-book"></i> Save to Journal</b-button>
            <hr v-if="totalCaloriesBurned && selectedFood" />
            <p v-if="totalCaloriesBurned && selectedFood">
              That's <b>{{ quantity }} x {{ selectedFood }}</b>
            </p>
            <small
              v-if="totalCaloriesBurned && selectedFood"
              class="has-text-grey-light"
            >You can't outwork bad diet, most of the foods are ok in
              moderation, even pizza :)</small>
          </div>
        </transition-group>
      </div>
    </section>
  </div>
</template>

<script>
// import ApiService from '@/services/ApiService'
import BackButton from '@/components/BackButton';
export default {
  name: 'CbcCalculator',
  components: {BackButton},
  data() {
    return {
      showPicker: false,
      showActivityPicker: false,
      showFoodPicker: false,
      columns: ['Metric', 'Imperial'],
      units: 'Metric',
      weight: null,
      quantity: 1,
      mins: null,
      heightMetric: null,
      heightFeet: null,
      heightInch: null,
      age: null,
      activityChoices: [
        'Aerobics: low impact  ',
        'Aerobics: high impact  ',
        'Aerobics: water  ',
        'Cycling',
        'Circuit Training: general  ',
        'Stair Step Machine',
        'Weight Lifting: general  ',
        'Weight Lifting: vigorous  ',
        'Basketball',
        'Bicycling: BMX or mountain  ',
        'Bicycling: 12-13.9 mph  ',
        'Bicycling: 14-15.9 mph  ',
        'Boxing',
        'Football: competitive  ',
        'Football: touch, flag, general  ',
        'Frisbee  ',
        'Golf',
        'Gymnastics  ',
        'Handball',
        'Hiking',
        'Horseback Riding',
        'Ice Skating',
        'Martial Arts',
        'Racquetball',
        'Rock Climbing',
        'Rollerblade Skating  ',
        'Rope Jumping',
        'Running (cross-country)  ',
        'Skiing (cross-country)',
        'Skiing (downhill)',
        'Swimming',
        'Tennis',
        'Volleyball',
        'Beach volleyball',
        'Walking',
        'Water Skiing  ',
        'Water Polo  ',
        'Whitewater: rafting, kayaking  ',
        'Chopping &amp; Splitting Wood  ',
        'Gardening',
        'Housecleaning',
        "Children's Games",
        'Sex',
        'Shoveling snow',
      ],
      activity: null,
      activityIndex: -1,
      activityValues: [
        0.044,
        0.056,
        0.032,
        0.056,
        0.064,
        0.048,
        0.024,
        0.048,
        0.064,
        0.068,
        0.064,
        0.08,
        0.072,
        0.072,
        0.064,
        0.024,
        0.044,
        0.032,
        0.096,
        0.048,
        0.032,
        0.056,
        0.08,
        0.08,
        0.088,
        0.056,
        0.08,
        0.072,
        0.064,
        0.048,
        0.048,
        0.056,
        0.024,
        0.064,
        0.032,
        0.048,
        0.08,
        0.04,
        0.048,
        0.036,
        0.028,
        0.04,
        0.012,
        0.048,
      ],
      selectedFood: null,
      foodChoices: [
        'Snickers Bar 48g',
        'Pringles Can 200g',
        'Coke 250ml',
        'Cheeze Pizza 8 slices(12")',
        'Strawberry Banana Smoothie (medium)',
      ],
      foodChoicesKcals: [245, 1028, 108, 1800, 240],
      totalCaloriesBurned: null,
    }
  },
  watch: {
    weight() {
      this.calculate()
    },
    mins() {
      this.calculate()
    },
    activity() {
      this.calculate()
    },
    selectedFood() {
      this.calculate()
    },
  },
  created() {
    this.calculate()
  },
  methods: {
    calculate() {
      if (this.weight && this.mins && this.activity) {
        let weight = 0
        if (this.units === 'Metric') {
          weight = parseInt(this.weight) * 2.20462
        } else {
          weight = this.weight
        }

        this.totalCaloriesBurned = (
          weight *
          this.mins *
          this.activityValues[this.activityIndex]
        ).toFixed(0)
        if (this.selectedFood) {
          this.quantity = (
            this.totalCaloriesBurned /
            this.foodChoicesKcals[this.foodChoices.indexOf(this.selectedFood)]
          ).toFixed(1)
        }
      }
    },
    onConfirm(value) {
      this.units = value
      this.showPicker = false
    },
    onConfirmActivity(value) {
      this.activity = value
      this.showActivityPicker = false
      this.activityIndex = this.activityChoices.indexOf(value)
    },
    onConfirmFood(value) {
      this.selectedFood = value
      this.showFoodPicker = false
    },
    saveToJournal() {},
  },
}
</script>
<style scoped>
#saveToJournalButton{
  margin: 20px;
}
#resultDiv {
  margin-top: 10px;
}
hr {
  margin: 0.5rem 0;
}
</style>