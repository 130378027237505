<template>
  <div class="feed container" style="background: #ffffff">
    <div class="hero"><h4 class="title is-4">Movies</h4></div>
    <section>
      <div></div>
    </section>
  </div>
</template>
<script>
// import Tile from '@/components/tiles/Tile'
import ApiService from '@/services/ApiService'
import { updatePosts } from '@/helpers/PostHelper'

export default {
  name: 'Movies',
  components: {
    // Tile
    // MembersTemplate
  },
  data() {
    return {
      items: [],
      currentPage: 0,
      category: 'movies',
    }
  },
  created() {
    this.getPosts()
  },
  methods: {
    getData() {
      ApiService.smallPosts
        .getCategory('movies', this.currentPage)
        .then((response) => {
          let items = response.data.slice(0, 20)
          items = updatePosts(items)
          this.items = items
        })
    },
  },
}
</script>
