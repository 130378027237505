<template>
  <div id="screenPage" class="contactus">
    <div class="hero">
      <h4 class="title is-4">Feedback</h4>
    </div>
    <section class="max500">
      <h4><small class="headerSmall is-size-6">Any issues/bugs ?</small></h4>
      <b-input
        v-model="item.issues"
        type="textarea"
        maxlength="250"
        placeholder="Enter here"
      ></b-input>
      <h4>
        <small class="headerSmall is-size-6">What you don't like ?</small>
      </h4>
      <b-input
        v-model="item.dlike"
        type="textarea"
        maxlength="250"
        placeholder="Enter here"
      ></b-input>
      <h4>
        <small class="headerSmall is-size-6">What you like about app/website ?</small>
      </h4>
      <b-input
        v-model="item.like"
        type="textarea"
        maxlength="250"
        placeholder="Enter here"
      ></b-input>
      <b-field>
        <!-- Label left empty for spacing -->
        <p class="control" style="margin-top: 20px">
          <b-button @click="submitFeedback" class="button is-primary" icon-left="a far fa-check">Send message</b-button>
        </p>
      </b-field>
    </section>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
export default {
  name: 'Feedback',
  components: {},
  props: {
    // id: Number
  },
  data() {
    return {
      item: {},
    }
  },
  computed: {

  },
  mounted() {},
  methods: {
    submitFeedback(){
      ApiService.feedback.submitFeedback(this.item).then(() => {
        this.item = {};
        this.$notify({ type: 'success', message: 'Thank you for your feedback!' })
      })
    }
  },
}
</script>