<template>
  <div id="screenPage">
    <div class="hero">
      <h4 class="title is-4">
        <BackButton /> Basal Metabolic Rate
        <small class="headerSmall">Calculator</small>
      </h4>
    </div>
    <section>
      <div>
        <div style="max-width: 400px; margin: auto">
          <van-field
            readonly
            clickable
            name="picker"
            :value="units"
            label="Units:"
            size="large"
            placeholder="Select Metric unit"
            label-width="7em"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              confirm-button-text="Confirm"
              cancel-button-text="Cancel"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            v-model="age"
            type="number"
            size="large"
            label="Age"
            placeholder="Age"
            maxlength="3"
            label-width="7em"
          />
          <van-field
            v-model="weight"
            type="number"
            :label="units === 'Metric' ? 'Weight (kg)' : 'Weight (lbs)'"
            placeholder="Weight"
            size="large"
            label-width="7em"
            maxlength="4"
          />
          <van-field
            v-if="units === 'Metric'"
            v-model="heightMetric"
            type="number"
            label="Height (cm)"
            placeholder="Height"
            size="large"
            label-width="7em"
            maxlength="3"
          />
          <van-field
            v-if="units === 'Imperial'"
            type="number"
            label="Height (ft/inch)"
            placeholder="Height"
            label-width="7em"
            ><template #input>
              <input
                v-model="heightFeet"
                class="van-field__control"
                name="ft"
                type="tel"
                placeholder="feet"
                maxlength="2"
              />
              <input
                v-model="heightInch"
                name="inch"
                class="van-field__control"
                type="tel"
                placeholder="inch"
                maxlength="2"
              />
            </template>
          </van-field>
          <van-field
            readonly
            clickable
            name="picker2"
            :value="dailyActivity"
            size="large"
            label="Daily activity:"
            placeholder="Select Daily Activity"
            label-width="7em"
            @click="showDailyActivityPicker = true"
          />
          <van-popup v-model="showDailyActivityPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="activityChoices"
              confirm-button-text="Confirm"
              cancel-button-text="Cancel"
              @confirm="onConfirmDailyActivity"
              @cancel="showDailyActivityPicker = false"
            />
          </van-popup>
          <span v-if="!bmr" class="title is-7 has-text-grey-light"
            >Please fill above to reveal result</span
          >
        </div>
        <transition-group name="slide-fade" mode="out-in">
          <van-empty v-if="!bmr" :key="'1'" image="search" />
          <div v-if="bmr" id="resultDiv" key="2">
            Your BMR is: <b>{{ bmr }} kcal/day</b><br />
            <b-button
              v-if="bmr"
              id="saveToJournalButton"
              type="is-primary is-light"
              @click="saveToJournal"
              ><i class="fas fa-book"></i> Save to Journal</b-button
            >
            <hr v-if="bmr" />
            <p>You need {{ energyNeeds }} kcal per day.</p>
          </div>
        </transition-group>
      </div>
    </section>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import BackButton from '@/components/BackButton'
export default {
  name: 'BmrCalculator',
  components: { BackButton },
  data() {
    return {
      showPicker: false,
      showDailyActivityPicker: false,
      columns: ['Metric', 'Imperial'],
      activityChoices: [
        'No sport/exercise',
        'Light activity (1-3 times per week)',
        'Moderate activity (3-5 times per week)',
        'High activity (everyday exercise)',
        'Extreme activity (professional athlete)',
      ],
      dailyActiveIndex: -1,
      dailyActivityValues: [0.2, 0.375, 0.55, 0.725, 0.9],
      units: 'Metric',
      dailyActivity: null,
      weight: null,
      heightMetric: null,
      heightFeet: null,
      heightInch: null,
      age: null,
      bmr: null,
      energyNeeds: null,
      summaryText: '',
      explanationText: '',
    }
  },
  watch: {
    weight() {
      this.calculate()
    },
    heightMetric() {
      this.calculate()
    },
    heightInch() {
      this.calculate()
    },
    age() {
      this.calculate()
    },
    dailyActiveIndex() {
      this.calculate()
    },
  },
  created() {},
  methods: {
    onConfirm(value) {
      this.units = value
      this.showPicker = false
    },
    onConfirmDailyActivity(value) {
      this.dailyActivity = value
      this.showDailyActivityPicker = false
      this.dailyActiveIndex = 1
    },
    calculate() {
      if (
        (this.heightMetric || (this.heightFeet && this.heightInch)) &&
        this.age &&
        this.weight &&
        this.dailyActiveIndex > -1
      ) {
        let inch = 0
        let lbs = 0
        let BMR = 0
        if (this.units === 'Metric') {
          inch = parseInt(this.heightMetric) * 0.393701
          lbs = parseInt(this.weight) * 2.20462
        } else {
          inch = parseInt(this.heightFeet) * 12 + parseInt(this.heightInch)
          lbs = parseInt(this.weight)
        }
        console.log('lbs ', lbs)
        console.log('inch ', inch)
        BMR = 655 + 4.3 * lbs + 4.7 * inch - 4.7 * this.age
        const extraEnergy =
          BMR * this.dailyActivityValues[this.dailyActiveIndex]
        this.bmr = BMR.toFixed(0)
        this.energyNeeds = (BMR + extraEnergy).toFixed(0)
      }
    },
    saveToJournal() {
      ApiService.journal.saveQuickEntry({
        template_id: 4,
        value: this.bmr,
        type: 'number',
      })
    },
  },
}
</script>
<style scoped>
#saveToJournalButton {
  margin-top: 20px;
}
#resultDiv {
  /* margin-left: 50px; */
  margin-top: 40px;
  text-align: center;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>